<template>
  <div class="buyerentitle">
    <div class="row">
      <div class="col-md-12">
        <h2 class="title">Entitlements</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-xs-6"></div>
      <div class="col-md-2 col-xs-2">
        <label>SMS</label>
      </div>
      <div class="col-md-2 col-xs-2">
        <label>Email</label>
      </div>
      <!-- <div class="col-md-2">
        <label>Push Notification</label>
      </div> -->
      <div class="col-md-2 col-xs-2"></div>
    </div>
    <div class="row">
      <div class="col-md-4 col-xs-6">
        <label>Weekly</label>
      </div>
      <div class="col-md-2 col-xs-2">
        <input
          type="checkbox"
          v-model="weekly[0].sms"
          @change="validationCheckBox('weekly', 'sms')"
        >
      </div>
      <div class="col-md-2 col-xs-2">
        <input
          type="checkbox"
          v-model="weekly[0].email"
          @change="validationCheckBox('weekly', 'email')"
        >
      </div>
      <!-- <div class="col-md-2">
        <input
          type="checkbox"
          v-model="weekly[0].push_notification"
          @change="validationCheckBox('weekly')"
        >
      </div> -->
      <div class="col-md-2"></div>
    </div>
    <div class="row">
      <div class="col-md-4 col-xs-6">
        <label>Monthly</label>
      </div>
      <div class="col-md-2 col-xs-2">
        <input
          type="checkbox"
          v-model="monthly[0].sms"
          @change="validationCheckBox('monthly', 'sms')"
        >
      </div>
      <div class="col-md-2 col-xs-2">
        <input
          type="checkbox"
          v-model="monthly[0].email"
          @change="validationCheckBox('monthly', 'email')"
        >
      </div>
      <!-- <div class="col-md-2">
        <input
          type="checkbox"
          v-model="monthly[0].push_notification"
          @change="validationCheckBox('monthly')"
        >
      </div> -->
      <div class="col-md-2 col-xs-2"></div>
    </div>
    <div class="row">
      <div class="col-md-4 col-xs-6">
        <label>Disable</label>
      </div>
      <div class="col-md-2 col-xs-2">
        <input
          type="checkbox"
          v-model="disable[0].sms"
          @change="validationCheckBox('disable', 'sms')"
        >
      </div>
      <div class="col-md-2 col-xs-2">
        <input
          type="checkbox"
          v-model="disable[0].email"
          @change="validationCheckBox('disable', 'email')"
        >
      </div>
      <!-- <div class="col-md-2">
        <input
          type="checkbox"
          v-model="disable[0].push_notification"
          @change="validationCheckBox('disable')"
        >
      </div> -->
      <div class="col-md-2 col-xs-2"></div>
    </div>
    <br/>
    <div class="row">
      <div class="col-md-7 align-end">
        <button
          type="button"
          data-toggle="tooltip"
          class="btn buyerprof-sbtn"
          data-original-title="View"
          @click="submit"
        >
          Submit
        </button>
      </div>
    </div>
  </div> 
</template>
<script>
import mixinProfile from "../../../mixins/buyer/APIProfile";
export default {
  mixins: [mixinProfile],
  components: {},
  props: {
    value: {
      type: String
    }
  },
  data () {
    return {
      monthly: [
        {
          sms: false,
          email: false,
          push_notification: false
        }
      ],
      weekly: [
        {
          sms: false,
          email: false,
          push_notification: false
        }
      ],
      disable: [
        {
          sms: false,
          email: false,
          push_notification: false
        }
      ],
      mode: "",
      breadcrumb: [
        {
          text: "News Feeds",
          href: "/merchant-dashbord",
          active: false
        },
      ]
    }
  },
  watch: {},
  created() {
    this.handleBreadcrumb();
    this.handleData();
  },
  methods: {
    handleBreadcrumb() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    handleData: async function() {
      try {
        let res = await this.getProfileBacicInfo();
        res.news_feed_method = res.news_feed_method.split("");
        if (res.news_feed_status === 1) {
          this.weekly[0].sms = res.news_feed_method[0] === "1" ? true : false;
          this.weekly[0].email = res.news_feed_method[1] === "1" ? true : false;
          this.weekly[0].push_notification = res.news_feed_method[2] === "1" ? true : false;

          if (this.weekly[0].sms === true && this.weekly[0].email === false) {
            this.disable[0].sms = false
            this.disable[0].email = true
          } else if (this.weekly[0].sms === false && this.weekly[0].email === true) {
            this.disable[0].sms = true
            this.disable[0].email = false
          } else {
            this.disable[0].sms = false
            this.disable[0].email = false
          }

        } else if (res.news_feed_status === 2) {
          this.monthly[0].sms = res.news_feed_method[0] === "1" ? true : false;
          this.monthly[0].email = res.news_feed_method[1] === "1" ? true : false;
          this.monthly[0].push_notification = res.news_feed_method[2] === "1" ? true : false;

          if (this.monthly[0].sms === true && this.monthly[0].email === false) {
            this.disable[0].sms = false
            this.disable[0].email = true
          } else if (this.monthly[0].sms === false && this.monthly[0].email === true) {
            this.disable[0].sms = true
            this.disable[0].email = false
          } else {
            this.disable[0].sms = false
            this.disable[0].email = false
          }

        } else if(res.news_feed_status === 3) {
          this.disable[0].sms = res.news_feed_method[0] === "1" ? true : false;
          this.disable[0].email = res.news_feed_method[1] === "1" ? true : false;
          this.disable[0].push_notification = res.news_feed_method[2] === "1" ? true : false;
        }
      } catch (error) {
        console.log(error);
        // this.$swal.fire({
        //   position: 'center',
        //   type: 'erro',
        //   title: 'Could not get details!',
        //   showConfirmButton: false,
        //   timer: 1500
        // })
      }
    },
    submit: async function() {
      try {
        let object = {
          news_feed_status: this.mode,
          news_feed_method: ""
        };
        let f_digit = "";
        let s_digit = "";
        let t_digit = "";
        if (object.news_feed_status === 1) {
          f_digit = this.weekly[0].sms === true ? "1" : "0";
          s_digit = this.weekly[0].email === true ? "1" : "0";
          t_digit = this.weekly[0].push_notification === true ? "1" : "0";
          object.news_feed_method = f_digit + s_digit + t_digit;
        } else if (object.news_feed_status === 2) {
          f_digit = this.monthly[0].sms === true ? "1" : "0";
          s_digit = this.monthly[0].email === true ? "1" : "0";
          t_digit = this.monthly[0].push_notification === true ? "1" : "0";
          object.news_feed_method = f_digit + s_digit + t_digit;
        }  else if (object.news_feed_status === 3) {
          f_digit = this.disable[0].sms === true ? "1" : "0";
          s_digit = this.disable[0].email === true ? "1" : "0";
          t_digit = this.disable[0].push_notification === true ? "1" : "0";
          object.news_feed_method = f_digit + s_digit + t_digit;
        }

        await this.putProfileBacicInfo(object);
        this.$swal.fire({
          position: 'center',
          type: 'success',
          title: 'Successfully submit!',
          showConfirmButton: false,
          timer: 1500
        })
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'erro',
          title: 'Could not submit!',
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    validationCheckBox(type, option) {
      if (type === "monthly") {
        this.mode = 2;

        if (option === "sms") {
            if(this.monthly[0].sms === true) {
              this.weekly[0].sms = false;
              this.disable[0].sms = false;
            }
          } else if (option == "email") {
             if(this.monthly[0].email === true) {
              this.weekly[0].email = false;
              this.disable[0].email = false;
            }
          }
        this.weekly[0].push_notification = false;
        this.disable[0].push_notification = false;

      } else if (type === "weekly") {
        this.mode = 1;
        
          if (option === "sms") {
            if(this.weekly[0].sms === true) {
              this.monthly[0].sms = false;
              this.disable[0].sms = false;
            }
          } else if (option == "email") {
             if(this.weekly[0].email === true) {
              this.monthly[0].email = false;
              this.disable[0].email = false;
            }
          }

        this.monthly[0].push_notification = false;
        this.disable[0].push_notification = false;

      } else if (type === "disable") {
        this.mode = 3;
        if (option === "sms") {
          if(this.disable[0].sms === true) {
              this.weekly[0].sms = false;
              this.monthly[0].sms = false;
              
            }
        } else if (option === "email") {
            if(this.disable[0].email === true) {
              this.weekly[0].email = false;
              this.monthly[0].email = false;
            }
        }
      }
    }
  }
}
</script>
<style scoped>
.align-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.buyerentitle .buyerprof-sbtn{
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.buyerentitle .buyerprof-sbtn:hover{
  color: #000000;
}
</style>