<template>
  <div class="buyerentitle">
    <div class="row">
      <div class="col-md-12">
        <h2 class="title">Followed Stores</h2>
      </div>
    </div>
    <div v-if="this.storeList.length === 0" class="row">
      <div class="col-md-12 text-center">
        <p>No records found</p>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-3" v-for="(item, index) in storeList" :key="index">
        <ul>
          <li class="list-group-item" @click="pushStore(item.store._id)">
            <img slot="image" :src="item.store.profile_image ? item.store.profile_image : 'https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629963258/cochchi/mercahnt/store/1629963257333.webp'" alt="Card image cap" class="card-img-top" style="cursor: pointer;">
            <a>{{item.store.store_name}}</a>
          </li>
        </ul>
      </div>
    </div>
    </div>
</template>
<script>
import mixinProfile from "../../../mixins/buyer/APIProfile";
export default {
  mixins: [mixinProfile],
  components: {},
  props: {
    value: {
      type: String
    }
  },
  data () {
    return {
      storeList:[],
      storeListDammy:[]
    }
  },
  watch: {},
  mounted(){
    this.handleData();
  },
  created() {
    this.handleBreadcrumb();
  },
  methods: {
    handleBreadcrumb() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    handleData: async function() {
      try {
        let res = await this.getfollwstoreList();
        this.storeList = res;
        // let arr1 = [];
        // for (let index = 0; index < this.storeList.length; index += 4) {
        //     let arr = this.storeList.slice(index, index+4);
        //     arr1.push(arr);
        // }
        // this.storeListDammy = arr1;
      } catch (error) {
        console.log(error);
      }
    },
    pushStore: function (id) {
      this.$router.push({ name: "Store Product", params: { id: id } });
    },
  }
}
</script>
<style scoped>
.align-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.buyerentitle .buyerprof-sbtn{
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.buyerentitle .buyerprof-sbtn:hover{
  color: #000000;
}

.list-group-item {
  display: flex;
  padding: .75rem 1.25rem;
  margin-bottom: .5rem !important;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  color: #5c6873;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  min-height: 170px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.list-group-item img {
  height: 100px;
}

.list-group-item a:hover {
  color: #5c6873 !important;
}

.list-group-item:hover {
  background-color: #e4e7ea;
   color: #23282c;
}
</style>