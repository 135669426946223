<template>
    <div>
        <p class="lead">Subscribe to Leasing Services</p>
        <div class="row">       
            <div class="">
                <!-- Product Tabs -->
				<div class="producttab " style="margin: 0px;">
					<div class="tabsslider  vertical-tabs col-xs-12">

						<ul class="nav nav-tabs col-lg-2 leasing-ul">
							<li class="active"><a data-toggle="tab" href="#tab-1">SLT </a></li> 
						</ul>
                        
						<div class="tab-content col-lg-10">
                            <div class="row"></div>
							    <div id="tab-1" class="tab-pane fade active in">

                                <div class="col-sm-12" v-if="!showLeaseData">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-8 col-sm-12">
                                            <div class="form-group">
                                                <label for="input-middlename" class="control-label">SLT ID</label>
                                                <input type="password" required class="form-control" placeholder="ID">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-8 col-sm-12">
                                            <div class="form-group">
                                                <label for="input-middlename" class="control-label">Mobile Number</label>
                                                <input type="number" id="password" name="password" class="form-control" placeholder="Phone Number" >
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="row">
                                        <div class="col-lg-6 col-md-8 col-sm-12">
                                            <div class="form-group text-left">
                                                <button class="btn btn-primary" @click="verifySLT()" >Verify</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>	

                                <!--Middle Part Start-->
                                <div id="content" class="col-sm-12" v-if="showLeaseData">
                                    <h2 class="title">SLT Lease Information</h2>

                                    <table class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <td colspan="2" class="text-left">Lease Details</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="width: 50%;" class="text-left"> <b>Lease ID:</b> #214521
                                                    <br>
                                                    <b>Date Added:</b> 20/06/2016</td>
                                                <td style="width: 50%;" class="text-left"> <b>Payment Method:</b> Installments
                                                    <br>
                                                    <b>Settlement Date:</b> 20/06/2016 </td>
                                            </tr>
                                        </tbody>
                                    </table> 
                                    <div class="  col-md-12">
                                        <div class="row" style="font-size: 16px;">
                                            <b>Lease Amount</b> : LKR 50000.00
                                        </div>
                                        <div class="row" style="font-size: 16px;">
                                            <b>Used Amount</b> : LKR 10000.00
                                        </div>
                                        <div class="row" style="font-size: 16px;">
                                            <b>Remaining Balance</b> : LKR 40000.00
                                        </div> 
                                    </div> 
                                    <div class="  col-md-12">
                                        <div class="row">
                                            <h3>Payment History</h3>
                                            <table class="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <td class="text-left">Date Added</td>
                                                        <td class="text-left">Amount</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-left">20/06/2016</td>
                                                        <td class="text-left">LKR 5000</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">21/06/2016</td>
                                                        <td class="text-left">LKR 7000</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">24/06/2016</td>
                                                        <td class="text-left">LKR 3000</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> 
                                    </div> 
                                     
                                    
                                    <div class="buttons clearfix">
                                        <div class="pull-right"><a class="btn btn-primary" href="#">Continue</a>
                                        </div>
                                    </div>



                                </div>
                                <!--Middle Part End-->	

							</div>	
                          </div>						  
						</div>
					</div>
				</div>
				<!-- //Product Tabs -->
            </div>
        </div>
    </div>
</template>
<script>
import mixinProfile from "../../../mixins/buyer/APIProfile";
export default {
  mixins: [mixinProfile],
  data: function () {
    return {
        changePassword: {},
        showLeaseData: false
    }
   },
   methods: {
    resetPassword: async function() {
        try {
            await this.putProfileBacicInfo(this.changePassword);
            this.$swal.fire({
            position: 'center',
            type: 'success',
            title: 'success change password',
            showConfirmButton: false,
            timer: 1500
            })

            this.$store.dispatch("logoutbuyer");
            this.$router.push('/buyer-login').catch(err => {});
        } catch (error) {
            console.log(error);
        }
    },
    verifySLT() {
        this.showLeaseData = true;
    }
   }
}
</script>
<style scoped>
.leasing-ul{
    min-height: 0px !important;
}

</style>
