<template>
    <div class="buyerprofaddr">
        <p class="lead">You can change your address here.</p>
        <div class="row">
            <div class="col-sm-12">
            <div  v-for="(item, val) in addressArray" :key="'billmain_'+val">
              <div v-if="val == 1">
              <p class="lead">Billing Address</p>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label class="control-label">Country</label>
                            <select class="form-control"  @change="changeCountries($event.target.value, val, true); " v-model="item.country" :disabled="val == 0 && billingsame" >
                                <option value=""> --- Please Select --- </option>
                                <option v-for="(item, val) in countries" :key="'bill_'+val" :value="item._id">{{ item.country_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label class="control-label">Province</label>
                            <select class="form-control"  @change="changeProvince($event.target.value, val, true); " v-model="item.province"  :disabled="val == 0 && billingsame"  >
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in provinceArray[val]" :key="'bill_'+val" :value="item._id">{{ item.province_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label class="control-label">District / Area</label>
                           <select class="form-control" @change="changeRegion($event.target.value, val, true)" v-model="item.region"  :disabled="val == 0 && billingsame" >
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in regionArray[val]" :key="'bill_'+val" :value="item._id">{{ item.region_name}}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label class="control-label">City</label>
                            <select class="form-control" v-model="item.city" @change="changeCity($event.target.value, val, true)"  :disabled="val == 0 && billingsame" >
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in cityArray[val]" :key="'bill_'+val" :value="item._id">{{ item.city_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-sm-6">
                        <label class="control-label " for="input-password">Post Code</label>
                        <input type="text" class="form-control" @change="billingSame()" v-model="item.postal_code"  :disabled="val == 1 && billingsame"  />
                    </div>
                </div>  -->
                <br/>

                <div class="row">
                    <div class="form-group required col-sm-12">
                        <label class="control-label" for="input-password">Address Line 1</label>
                        <input type="text" class="form-control" placeholder="" @input="billingSame()" v-model="item.address_line_1"  :disabled="val == 0 && billingsame" />
                    </div>
                </div>  

                <div class="row">
                    <div class="form-group required col-sm-12">
                        <label class="control-label" for="input-password">Address Line 2</label>
                        <input type="text" class="form-control" placeholder="" @input="billingSame()" v-model="item.address_line_2"  :disabled="val == 0 && billingsame" />
                    </div>
                </div>  
                
                <br/>
                <div class="row" v-if="val === 1">
                    <div class="col-sm-4">       
                        <label class="radio-inline">
                        <input type="checkbox" v-model="billingsame" @change="billingSame(true)" :value="true" checked name="billing"> Deliver to this address
                        </label>                     
                        
                    </div>
                    <!-- <div class="col-sm-3 text-right" v-if="val === valIndex && val <= 0">
                        <a class="btn btn-info btn-sm" @click="addNewClick()">
                          Add billing address &nbsp;&nbsp;<span class="glyphicon glyphicon-plus"></span>
                        </a>
                    </div> -->
                    <div class="col-sm-6">
                    </div>     
                </div>
                <br/>
              </div>
            </div>

            <div  v-for="(item, val) in addressArray" :key="'shipmain_'+val">
              <div v-if="val == 0">
              <p class="lead">Shipping Address</p>
                <div class="row" v-if="val === 0">
                    <!-- <div class="col-sm-4">       
                        <label class="radio-inline" style="padding-left:0px;">
                        <input type="checkbox" v-model="billingsame" @change="billingSame(true)" :value="true" checked name="billing"> Deliver to this address
                        </label>                     
                        
                    </div> -->
                    <!-- <div class="col-sm-3 text-right" v-if="val === valIndex && val <= 0">
                        <a class="btn btn-info btn-sm" @click="addNewClick()">
                          Add billing address &nbsp;&nbsp;<span class="glyphicon glyphicon-plus"></span>
                        </a>
                    </div> -->
                    <div class="col-sm-6">
                    </div>     
                </div>
                <br/>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label class="control-label">Country</label>
                            <select class="form-control"  @change="changeCountries($event.target.value, val, true); " v-model="item.country" :disabled="val == 0 && billingsame" >
                                <option value=""> --- Please Select --- </option>
                                <option v-for="(item, val) in countries" :key="'ship_'+val" :value="item._id">{{ item.country_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label class="control-label">Province</label>
                            <select class="form-control"  @change="changeProvince($event.target.value, val, true); " v-model="item.province"  :disabled="val == 0 && billingsame"  >
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in provinceArray[val]" :key="'ship_'+val" :value="item._id">{{ item.province_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label class="control-label">District / Area</label>
                           <select class="form-control" @change="changeRegion($event.target.value, val, true)" v-model="item.region"  :disabled="val == 0 && billingsame" >
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in regionArray[val]" :key="'ship_'+val" :value="item._id">{{ item.region_name}}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label class="control-label">City</label>
                            <select class="form-control" v-model="item.city" @change="changeCity($event.target.value, val, true)"  :disabled="val == 0 && billingsame" >
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in cityArray[val]" :key="'ship_'+val" :value="item._id">{{ item.city_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-sm-6">
                        <label class="control-label " for="input-password">Post Code</label>
                        <input type="text" class="form-control" @change="billingSame()" v-model="item.postal_code"  :disabled="val == 1 && billingsame"  />
                    </div>
                </div>  -->
                <br/>

                <div class="row">
                    <div class="form-group required col-sm-12">
                        <label class="control-label" for="input-password">Address Line 1</label>
                        <input type="text" class="form-control" placeholder=""  @input="billingSame()" v-model="item.address_line_1"  :disabled="val == 0 && billingsame" />
                    </div>
                </div>  

                <div class="row">
                    <div class="form-group required col-sm-12">
                        <label class="control-label" for="input-password">Address Line 2</label>
                        <input type="text" class="form-control" placeholder="" @input="billingSame()" v-model="item.address_line_2"  :disabled="val == 0 && billingsame" />
                    </div>
                </div>  
                <br/>
              </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group text-right">
                    <!-- <button class="btn btn-primary">Edit Address</!-->
                    <button class="btn buyerprof-sbtn" @click="submitProfileAddress" >Submit</button>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
    </div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinProfile from "@/mixins/buyer/APIProfile";
export default {
  mixins: [mixinCommon, mixinProfile],
  data: function () {
    return {
      valIndex: 0,
      countries: [],
      province: [],
      region: [],
      city: [],
      provinceArray:[],
      regionArray:[],
      cityArray:[],
      billingsame: false,
      addressArray: [{
        // address: "",
        address_line_1: "",
        address_line_2: "",
        country: "",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        is_shipping_address: true,
        is_biling_address: false,
        primary: false
      },{
        // address: "",
        address_line_1: "",
        address_line_2: "",
        country: "",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        is_shipping_address: false,
        is_biling_address: true,
        primary: false
      }],
      address:{
        // address: "",
        address_line_1: "",
        address_line_2: "",
        country: "",
        province: "",
        region: "",
        city: "",
        postal_code: "",
        is_shipping_address: false,
        is_biling_address: true,
        primary: false
      }
    }
  },
  created(){
    this.getCountryDetails();
    this.getPersanalInfo();
  },
  methods: {
    billingSame: async function(clicked = false){
     
      if(this.billingsame === true){
        if (this.provinceArray[1]) {
          this.provinceArray[0] = JSON.parse(JSON.stringify(this.provinceArray[1]));
        }
        if (this.regionArray[1]) {
          this.regionArray[0] = JSON.parse(JSON.stringify(this.regionArray[1]));
        }
        if (this.cityArray[1]) {
          this.cityArray[0] = JSON.parse(JSON.stringify(this.cityArray[1]));
        }
        if (this.addressArray[1]) {
          Object.assign(this.addressArray[0], this.addressArray[1])       
        }
      } else {
        if(clicked) {
          if (this.addressArray[0]) {
            Object.assign(this.addressArray[0], this.address)
          } 
          this.provinceArray[0] = [];
          this.regionArray[0] = [];
          this.cityArray[0] = [];
        }
      }
    },
    changeBilling: function(val, tickindex) {   
      for (let index = 0; index < this.addressArray.length; index++) {
        if(val === false){
          if(tickindex != index){
            this.addressArray[index].is_biling_address = true;
          }
        } else {
          if(tickindex != index){
            this.addressArray[index].is_biling_address = false;
          }
        }         
      }       
    },
    changeShipping: function(val, tickindex) {   
      for (let index = 0; index < this.addressArray.length; index++) {
        if(val === false){
          if(tickindex != index){
            this.addressArray[index].is_shipping_address = true;
          }
        } else {
          if(tickindex != index){
            this.addressArray[index].is_shipping_address = false;
          }
        }         
      }       
    },
    addNewClick: function() {
    if (this.addressArray.length <= 1) {
        if (this.valIndex === 0) {
            this.addressArray[0].primary = true;
        }
        this.valIndex = this.valIndex + 1;
        this.addressArray.push( this.address );
    }
    },
    getCountryDetails: async function() {
      try {
          let response = await this.getCountry(); 
          this.countries = response;
          this.changeCountries("5d8b9e5dfb195816aaea61b3", 1 , false);
          this.changeCountries("5d8b9e5dfb195816aaea61b3", 0 , true);
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    changeCountries: async function(event, index, useraction = false) {
      try {
        // alert("test");
          event ="5d8b9e5dfb195816aaea61b3";
          let response = await this.getProvince("5d8b9e5dfb195816aaea61b3");
          this.province = response;

          this.$set(this.provinceArray, index, response);
          this.regionArray[index] = [];
          this.cityArray[index] = [];
        
          // // RESETING 
           if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].province = "";
            this.addressArray[index].region = "";
            this.addressArray[index].city = "";
           } 

           if(this.billingsame === true){         
             this.$set(this.provinceArray, 1, response);      
            }

            this.billingSame();
              
      } catch (error) {
        this.$swal.fire({
            position: 'center',
            type: 'error',
            title: error.data.message,
            showConfirmButton: false,
            timer: 1500
          })      
      }
    },
    changeProvince: async function(event, index, useraction = false) {
      try {
          let response = await this.getRegion(event); 
          this.region = response;

          this.$set(this.regionArray, index, response);

           // RESETING 
          this.cityArray[index] = [];
          if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].region = "";
            this.addressArray[index].city = "";
          }


           if(this.billingsame === true){         
             this.$set(this.regionArray, 1, response);      
            }
           this.billingSame();    
              
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    changeRegion: async function(event, index, useraction = false) {
      try {
        let response = await this.getCity(event);           
        this.city = response;
        this.$set(this.cityArray, index, response);
        // RESETING  
        if(Object.keys(response).length === 0 || useraction === true){
          this.addressArray[index].city = ""; 
        }
        if(this.billingsame === true){         
            this.$set(this.cityArray, 1, response);      
          }
        this.billingSame();
        if (this.billingsame === true) {
          this.addressArray[0].postal_code = "";
          this.addressArray[1].postal_code = "";
        } else if (this.billingsame === false) {
          this.addressArray[index].postal_code = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    changeCity: async function(event, index, useraction = false) {
      let pCode = this.city.filter(obj => {
        return obj._id === event;
      });
      if (this.billingsame === true) {
        this.addressArray[0].postal_code = pCode[0].postal_code;
        this.addressArray[1].postal_code = pCode[0].postal_code;
        this.addressArray[1].city = pCode[0]._id;
      } else if (this.billingsame === false) {
        this.addressArray[index].postal_code = pCode[0].postal_code;
      }

      this.billingSame();
      
    },
    submitProfileAddress: async function() {
 
      let obj = {};  
      
      this.addressArray[0].is_biling_address = false;
      this.addressArray[0].is_shipping_address = true;
      delete this.addressArray[0]['_id']; 

      if(this.addressArray.length === 1){
       this.addressArray[1] = JSON.parse(JSON.stringify(this.addressArray[0]));
      }
  
      this.addressArray[1].is_biling_address = true;
      this.addressArray[1].is_shipping_address = false;
      delete this.addressArray[1]['_id']; 

        obj.addresses = this.addressArray;
    
        if(this.addressArray[0].address_line_1 === ""|| this.addressArray[1].address_line_1 === "" ){
          this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: 'Fill All Required Fields',
                  showConfirmButton: false,
                  timer: 1500
                })    
          return;
        }

        // if(this.addressArray[0].address_line_2 === ""|| this.addressArray[1].address_line_2 === "" ){
        //   this.$swal.fire({
        //           position: 'center',
        //           type: 'error',
        //           title: 'Fill All Required Fields',
        //           showConfirmButton: false,
        //           timer: 1500
        //         })    
        //   return;
        // }

        if(this.addressArray[0].country === "" || this.addressArray[1].country === "" ){
          this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: 'Fill All Required Fields',
                  showConfirmButton: false,
                  timer: 1500
                })    
          return;
        }

        if(this.addressArray[0].province === "" || this.addressArray[1].province === "" ){
          this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: 'Fill All Required Fields',
                  showConfirmButton: false,
                  timer: 1500
                })    
          return;
        }

        if(this.addressArray[0].region === "" || this.addressArray[1].region === "" ){
          this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: 'Fill All Required Fields',
                  showConfirmButton: false,
                  timer: 1500
                })    
          return;
        }

        if(this.addressArray[0].city === "" || this.addressArray[1].city === "" ){
          this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: 'Fill All Required Fields',
                  showConfirmButton: false,
                  timer: 1500
                })    
          return;
        }



        try { 
          let response = await this.putProfileAddress(obj);
          this.$swal.fire({
                  position: 'center',
                  type: 'success',
                  title: 'Submit success!',
                  showConfirmButton: false,
                  timer: 1500
                })   
        } catch (error) {
          this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: 'Submit error!',
                  showConfirmButton: false,
                  timer: 1500
                })      
        }
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        if (response.addresses.length === 0) {
            this.addressArray = [{
                address: "",
                country: "5d8b9e5dfb195816aaea61b3",
                province: "",
                region: "",
                city: "",
                postal_code: "",
                is_biling_address: false,
                is_shipping_address: true,
                primary: false
            },{
                address: "",
                country: "5d8b9e5dfb195816aaea61b3",
                province: "",
                region: "",
                city: "",
                postal_code: "",
                is_biling_address: true,
                is_shipping_address: false,
                primary: false
            }];
        } else {
            this.addressArray = response.addresses;

            for (let index = 0; index < this.addressArray.length; index++) {
              const element = this.addressArray[index];
              
              if(index == 0){
                if(this.addressArray[index].is_shipping_address === false){
                  this.addressArray = this.addressArray.reverse();
                }
              }
              
            }

            if(this.addressArray.length > 0){             
              if((this.addressArray[0].address === this.addressArray[1].address) 
              && (this.addressArray[0].country === this.addressArray[1].country) 
              && (this.addressArray[0].province === this.addressArray[1].province)   
              && (this.addressArray[0].region === this.addressArray[1].region) 
              && (this.addressArray[0].city === this.addressArray[1].city) 
              && (this.addressArray[0].postal_code === this.addressArray[1].postal_code) ){
                this.billingsame = true;
              } else {
                this.billingsame = false;
              } 
            }
            
            for (let index = 0; index < response.addresses.length; index++) {
              await this.changeCountries(response.addresses[index].country, index);
              await this.changeProvince(response.addresses[index].province, index);
              await this.changeRegion(response.addresses[index].region, index);
              this.addressArray = response.addresses; 
            } 

            
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
<style>
.buyerprofaddr .buyerprof-sbtn{
  background: #5779ae;;
  color: #ffffff;
  border: none;
}
.buyerprofaddr .buyerprof-sbtn:hover{
  color: #000000;
}
</style>
