<template>
<div>
<div v-if="!availableAddress" style="padding: 20px;border: padding: 20px;border: 1px solid rgb(221, 221, 221); background: white;">
    <ul class="nav nav-tabs">
        <!-- <li :class="isAddressFilled ? 'active' : ''"><a data-toggle="tab" href="#personal">Personal</a></li>
        <li :class="isAddressFilled ? '' : 'active'"><a data-toggle="tab" href="#address" >Addresses</a></li> -->
        <li :class="!isAddressFilled ? 'active' : ''"><a data-toggle="tab" href="#personal">Personal</a></li>
        <li :class="!isAddressFilled ? '' : 'active'"><a data-toggle="tab" href="#address" >Addresses</a></li>
        <li><a data-toggle="tab" href="#password">Change Password</a></li>
        <!-- <li><a data-toggle="tab" href="#leasing">Lending</a></li>
        <li :class="isSltCustomer ? 'active' : ''"><a data-toggle="tab" href="#SLTCustomer" v-if="isSltCustomer">SLT Customer</a></li>-->
        <li><a data-toggle="tab" href="#entitlements">Entitlements</a></li>
        <li><a data-toggle="tab" href="#follwers">Followed Stores</a></li> 
    </ul>
    <div class="tab-content" v-if="user_role != 2">
        <div id="personal" class="tab-pane fade in"
            v-bind:class="activeClassPersonal"
        >
            <ProfilePersonal />
        </div>
        <div id="address" class="tab-pane fade" 
            v-bind:class="activeClassAddress" >
            <ProfileAddress />
        </div>
        <div id="password" class="tab-pane fade">
            <ProfilePassword />
        </div>
        <!-- <div id="leasing" class="tab-pane fade">
            <Leasing />
        </div> -->
        <!-- <div id="SLTCustomer" class="tab-pane fade" 
            v-bind:class="activeClassAddress" 
             >
            <SLTCustomer />
        </div> -->
        <div id="entitlements" class="tab-pane fade" v-bind:class="activeClassPersonal">
            <Entitlements />
        </div>
        <div id="follwers" class="tab-pane fade" v-bind:class="activeClassPersonal">
            <ProfileFollwers />
        </div>
    </div>         
</div>
<div v-if="availableAddress" style="padding: 20px;border: padding: 20px;border: 1px solid rgb(221, 221, 221); background: white;">
    <ul class="nav nav-tabs">
        <!-- <li :class="isAddressFilled ? 'active' : ''"><a data-toggle="tab" href="#personal">Personal</a></li>
        <li :class="isAddressFilled ? '' : 'active'"><a data-toggle="tab" href="#address" >Addresses</a></li> -->
        <li :class="!availableAddress ? 'active' : ''"><a data-toggle="tab" href="#personal">Personal</a></li>
        <li :class="!availableAddress ? '' : 'active'"><a data-toggle="tab" href="#address" >Addresses</a></li>
        <li><a data-toggle="tab" href="#password">Change Password</a></li>
        <!-- <li><a data-toggle="tab" href="#leasing">Lending</a></li>
        <li :class="isSltCustomer ? 'active' : ''"><a data-toggle="tab" href="#SLTCustomer" v-if="isSltCustomer">SLT Customer</a></li>-->
        <li><a data-toggle="tab" href="#entitlements">Entitlements</a></li> 
        <li><a data-toggle="tab" href="#follwers">Follwers</a></li> 
    </ul>
    <div class="tab-content" style="margin-bottom: 0px !important" v-if="user_role != 2">
        <div id="personal" class="tab-pane fade in"
            v-bind:class="activeClassPersonalAddressAvailable"
        >
            <ProfilePersonal />
        </div>
        <div id="address" class="tab-pane fade" 
            v-bind:class="activeClassAddressAvailable" >
            <ProfileAddress />
        </div>
        <div id="password" class="tab-pane fade">
            <ProfilePassword />
        </div>
        <!-- <div id="leasing" class="tab-pane fade">
            <Leasing />
        </div> -->
        <!-- <div id="SLTCustomer" class="tab-pane fade" 
            v-bind:class="activeClassAddress" 
             >
            <SLTCustomer />
        </div> -->
        <div id="entitlements" class="tab-pane fade" v-bind:class="activeClassPersonalAddressAvailable">
            <Entitlements />
        </div>
        <div id="follwers" class="tab-pane fade" v-bind:class="activeClassPersonalAddressAvailable">
            <ProfileFollwers />
        </div>
    </div>         
</div>
<!-- <div id="bottom-banner">
</div> -->
</div>
</template>
<script>
// @ is an alias to /src
import ProfileFollwers from '@/components/buyer/profile/ProfileFollwers'
import Entitlements from '@/components/buyer/profile/Entitlements'
import ProfilePersonal from '@/components/buyer/profile/ProfilePersonal'
import ProfileAddress from '@/components/buyer/profile/ProfileAddress'
import ProfilePassword from '@/components/buyer/profile/ProfilePassword'
import Leasing from '@/components/buyer/profile/ProfileLeasing'
import SLTCustomer from '@/components/buyer/profile/ProfileSLTCustomer.vue'
import mixinProfile from "@/mixins/buyer/APIProfile";
export default {
mixins: [mixinProfile],
name: 'ProfileConfigure',
data(){
    return{
        availableAddress: false,
        activeTab: "personal",
        enablePersonalTab: true,
        isAddressFilled: false,
        selectedContactType:'',
        breadcrumb: [
            {
            text: "Profile",
            href: "#",
            active: true
            }
        ],
        user_role: 0
    }
},
components: {
    ProfileFollwers,
    Entitlements,
    ProfilePersonal,
    ProfileAddress,
    ProfilePassword,
    Leasing,
    SLTCustomer,

},
computed:{
    activeClassAddress(){
        if (!this.isAddressFilled) {
            return 'tab-pane fade in ';
        } else {
            return 'tab-pane fade in active';
        }
    },
    activeClassPersonal(){
        if (!this.isAddressFilled) {
            return 'active';
        } else {
            return 'tab-pane fade in';
        }
    },
    activeClassAddressAvailable(){
        if (!this.availableAddress) {
            return 'tab-pane fade in ';
        } else {
            return 'tab-pane fade in active';
        }
    },
    activeClassPersonalAddressAvailable(){
        if (!this.availableAddress) {
            return 'active';
        } else {
            return 'tab-pane fade in';
        }
    }
},
created: function () {
    this.init();
    this.handleBreadcrumb();
},
methods:{
    // init() {
    //     this.isSltCustomer = false; 
    //     let status= localStorage.getItem("slt_customer_status") != undefined ?  localStorage.getItem("slt_customer_status") : "" ;
    //     if(status=="enable"){
    //         this.isSltCustomer = true; 
    //     } else if (status=="disable"){
    //         this.isSltCustomer = false; 
    //     }
    // },
    init:  function() {
        try {

            if(localStorage.buyer_data){
                let userdata = JSON.parse(localStorage.buyer_data);
                if(userdata.hasOwnProperty('user_role')){
                this.user_role = userdata.user_role;
                } 
            }
            // let response = await this.getProfileBacicInfo();
            // if (response.addresses.length != 0) {
            //     // this.$router.push({ name: "Buyer Profile" });
            //    this.isAddressFilled = true;
            // } else{
            //    this.isAddressFilled = false;
            // }
            if(this.$route.params.cartchgadd){
                if(this.$route.params.cartchgadd != undefined){
                    if (this.$route.params.cartchgadd) {
                       this.isAddressFilled = true;
                    } else {
                        this.isAddressFilled = false;
                    }
                }
            }
            if(this.$route.params.availableAddress){
                if(this.$route.params.availableAddress != undefined){
                    if (this.$route.params.availableAddress) {
                       this.availableAddress = true;
                    } else {
                        this.availableAddress = false;
                    }
                }
            }
        }
        catch(e){
            throw(e);
        }
    },
    handleBreadcrumb: function() {		
        this.$store.commit("setBreadcrumbSwitchState", {
            action: null,
            payload: this.breadcrumb
        });
    }
}
}
</script>
<style scoped>
.nav-tabs>li>a:hover{
    color: #5779ae;
}

@media (max-width: 425px) {
    .nav-tabs>li>a {
        margin-right: 2px;
        line-height: 1.42857143;
        border: 1px solid transparent;
        border-radius: 4px 4px 0 0;
        margin-bottom: 6px;
        border: 1px solid #555;
        border-radius: 2px;
    }
    .nav-tabs>li {
        float: left;
        margin-bottom: -1px;
        margin-right: 4px;
    }
    .norec{
        text-align: left;
    }
}
</style>
