<template>
  <div class="buyerprofpersonal">
    <p class="lead">
      Hello,
      <strong style="color: #5779ae;font-weight: bold;">{{ basicInfo.first_name }} {{ basicInfo.last_name }}</strong> -
      Welcome.
    </p>
    <div class="row">
      <div id="buyerImg" class="col-lg-3 col-md-5 text-center">
        <div>
          <img
            slot="image"
            class="card-img-top"
            :src="contactImage"
            alt="Card image cap"
          />
        </div>
        <br />
        <br />
        <!-- <input type="file" class="btn btn-default mt-2 pt-1" @change="getFile" >Update Image</input> -->
        <button
          class="btn btn-sm buyerprof-sbtn profupload-btn"
          @click="pickProfPicture()"
        >
          Upload
        </button>
        <input
          type="file"
          class="inputfile"
          id="embedpollfileinput"
          ref="upprofpicture"
          style="display: none"
          @change="getFile"
        />
      </div>
      <div class="col-lg-9 col-md-7">
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="input-middlename" class="control-label"
                >First Name</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="First Name"
                v-model="basicInfo.first_name"
              />
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="input-middlename" class="control-label"
                >Middle Name</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Middle Name"
                v-model="basicInfo.middle_name"
              />
            </div>
          </div> -->
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="input-middlename" class="control-label"
                >Last Name</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Last Name"
                v-model="basicInfo.last_name"
              />
            </div>
          </div>
        </div>
        <div class="row" style="padding-bottom: 15px">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group email-input-block">
              <label for="input-middlename" class="control-label">Email</label>
              <input
                type="text"
                class="form-control"
                disabled
                placeholder="Email"
                v-model="basicInfo.email"
              />
              <div style="padding-top: 7px">
                <div :style="emailVerified">
                  <span style="font-size: 10px; color: green">
                    <i style="color: green" class="fa fa-check-circle"></i>
                    Email verified
                  </span>
                </div>
                <div :style="emailNotVerified">
                  <span style="font-size: 10px; color: red; margin-right: 15px">
                    <i style="color: red" class="fa fa-times-circle"></i> Email
                    not verified
                  </span>
                  <button
                    @click="verifyEmail()"
                    class="btn btn-sm buyerprof-sbtn"
                  >
                    Vefify Email
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="input-middlename" class="control-label"
                >Date of birth</label
              >
              <!-- <datepicker placeholder="Select Date" :format="customFormatter" v-model="basicInfo.dob" :bootstrap-styling="true">
                              <span slot="afterDateInput" class="animated-placeholder">
                              </span>
              </datepicker>-->
              <datepicker
                placeholder="Select Date"
                v-model="basicInfo.dob"
                :bootstrap-styling="true"
                ref="refDatePick"
              >
                <span slot="afterDateInput" class="animated-placeholder"></span>
              </datepicker>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="input-middlename" class="control-label">Gender</label>
              <select
                v-model="selectedGender"
                class="form-control select-arrow"
                style="width: 100%"
                id="genderSelect"
                @click="hideDefaultOption()"
              >
                <option
                  v-for="item in genderOption"
                  :key="item.key"
                  :value="item.value"
                >
                  {{ item.text }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="form-group input-group" style="margin-bottom: 0px;">
              <label for="input-middlename" class="control-label"
                >Mobile No</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Mobile No"
                disabled
                v-model="basicInfo.mobile"
              />
              <span class="input-group-btn" style="padding-top: 28px;">
                <button class="btn btn-default" style="background: #ffd338; border-color: rgb(255, 211, 56); height: 34px!important;" data-toggle="modal" data-target="#mobileVerifyModalContentCom" @click="viewModalMobileVerify(basicInfo.mobile)" type="button">Change No</button>
              </span>
            </div>

            <div style="padding-top: 7px">
              <div v-if="mobile_verify">
                <span style="font-size: 10px; color: green">
                  <i style="color: green" class="fa fa-check-circle"></i>
                  Mobile verified
                </span>
              </div>
            </div>

          </div>

          <div class="col-sm-4 col-md-4 col-xs-12">
            <div class="form-group">
              <label for="phonenumber">Home Number</label>
              <!-- <VuePhoneNumberInput
                ref="mobilecom"
                valid-color="green"
                error-color="red"
                @update="setCountryCode"
                @input="validatePhone(secondary_mobile)"
                v-model="basicInfo.secondary_mobile"
                :default-country-code="dialCode"
                :translations="{ phoneNumberLabel: '' }"
                no-example
                style="height:30px; cursor:auto;"
                id="mobileInput"
              /> -->
              <input
                type="tel"
                class="form-control"
                placeholder="Fixed line number"
                v-model="basicInfo.secondary_mobile"
                @input="fixedLineNumber(basicInfo.secondary_mobile)" 
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                :style="changeBorderFixedLine"
                maxlength="10"
                pattern="[0-9]{10}"
              />
            </div>
          </div>
        </div>

        <!-- <div class="row"> -->
          <!-- <div class="col-lg-4 col-md-12 col-sm-12">
                      <div class="form-group ">
                        <label for="input-middlename" class="control-label">Organization Type</label>
                          <select v-model="basicInfo.organization_type" class="form-control" style="width: 100%;">
                            <option v-for="item in OrganizationTypes" :key="item.key" :value="item.value">{{ item.text }}</option>
                          </select>
                      </div>
          </div>-->
          <!-- <div class="col-lg-4 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Organization</label>
                             <input type="text" class="form-control" placeholder="Organization" v-model="basicInfo.organization">
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Available Credit Limit</label>
                             <input type="text" class="form-control" placeholder="Available Credit Limit" v-model="basicInfo.credit">
                        </div>
          </div>-->
        <!-- </div> -->
        <!-- <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <input type="checkbox" name="newsletter" 
                        v-model="isSltCustomer"
                        @change="clickIsSltCustomer($event.target.checked)"
                      >
                      If you are a SLT Customer
                    </div>
        </div>-->
      </div>
      <div class="col-lg-12">
        <div class="form-group text-right">
          <button
            :disabled="!formIsValid1"
            style="float: right"
            class="btn buyerprof-sbtn"
            @click="submitProfile"
          >
            Submit
          </button>
          <div
            class="text-right"
            style="float: right; margin: 8px"
            v-if="blockLoading"
          >
            <div class="sk-chase" style="height: 20px; width: 20px">
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mobileVerifyModal
      @close="close"
      :mobileForPropval="mobileForPropval"
      :userType="userType"
    />
  </div>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import Datepicker from "vuejs-datepicker";
import mobileVerifyModal from "@/components/common/modals/mobileVerifyModal.vue";
import mixinProfile from "../../../mixins/buyer/APIProfile";
export default {
  mixins: [mixinProfile],
  components: {
    mobileVerifyModal,
    Datepicker,
    VuePhoneNumberInput,
  },
  data: function () {
    return {
      isSltCustomer: false,
      selectedGender: 0,
      basicInfo: {
        gender: 0,
        dob: null,
        fix_dial_code: "",
        fix_county_code: "",
      },
      countryCode: 0,
      dialCode: "LK",
      secondary_mobile: "",
      OrganizationTypes: [
        { key: 0, text: "Select Organization Type", value: 0 },
        { key: 1, text: "type 1", value: 1 },
        { key: 2, text: "type 2", value: 2 },
      ],
      genderOption: [
        { key: 0, text: "Select Gender", value: 0 },
        { key: 1, text: "Male", value: 1 },
        { key: 2, text: "Female", value: 2 },
      ],
      changeBorderFixedLine: {
        border: "1px solid #ccc",
      },
      fileUploadFormData: new FormData(),
      image: "",
      userType: "",
      mobileForPropval: "",
      gender: 0,
      dob: "",
      hmobileValid: true,
      hmobileError: "Enter valid phone number",
      blockLoading: false,
      fixedLineValid:true,
      emailVerified: {
        display: "none",
      },
      emailNotVerified: {
        display: "none",
      },
      mobile_verify: false
    };
  },
  computed: {
    formIsValid() {
      return this.basicInfo.secondary_mobile.length === 10;
    },
    formIsValid1() {
      return this.basicInfo.first_name !== '' && this.basicInfo.last_name !== '' && this.fixedLineValid;
    },
    contactImage: function () {
      if (this.image) {
        return this.image;
      } else {
        return "assets/image/catalog/about/cl-image-1.jpg";
      }
    },
    customFormatter(date) {
      return moment(date).format("YYYY/MM/DD");
    },
  },
  created() {
    this.getPersanalInfo();
    this.handelBasicInfo();
    $("#mobileVerifyModalContentCom").modal("hide");
    this.init();
  },
  beforemounted() {
    this.$nextTick(function () {
      const datePickerInputOuter = document.querySelector(".vdp-datepicker");
      const datePickerInput = datePickerInputOuter.querySelector("input");
      datePickerInput.classList.remove("form-control");
      datePickerInput.classList.add("datepnewclass");
      datePickerInput.style.width = "100%";
      datePickerInput.style.height = "42px";
    });
    if (this.$refs.mobilecom ) {
      this.$refs.mobilecom.$children[0].$el.children[0].style.top = "15px";
      this.$refs.mobilecom.$children[0].$el.childNodes[1].style.paddingTop =
        "0px";
      this.$refs.mobilecom.$el.children[0].style.maxWidth = "100px";
      this.$refs.mobilecom.$el.children[0].style.minWidth = "100px";
  
      this.$refs.mobilecom.$children[1].$el.firstChild.style.paddingTop = "2px";
    }

    const mobileInputTp = document.querySelector(".input-tel__input");
    mobileInputTp.setAttribute("maxlength", 12);
  },
  methods: {
    hideDefaultOption: function () {
      var genderSelElement = document.querySelector("#genderSelect");
      var genderOptions = genderSelElement.querySelectorAll("option");
      genderOptions[0].style.display = "none";
    },
    verifyEmail: async function () {
      try {
        let response = await this.buyerEmailVerify();
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Email verfication sent successfully!",
          showConfirmButton: false,
          timer: 1700,
        });
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Email verfication failed,try again!",
          showConfirmButton: false,
          timer: 1700,
        });
      }
    },
    setCountryCode(key) {
      this.countryCode = key.countryCallingCode;
      this.dialCode = key.countryCode;
      this.secondary_mobile = key.formatNational;
    },
    validatePhone(key) {
      if (this.countryCode == 94) {
        if (this.$refs.mobilecom) {
          if (key.length >= 10) {
            this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
              "1px solid green";
            this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
              "green 0px 0px 0px 0px";
            this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
              "green 0px 0px 0px 0px";
          }
          if (key.length < 10) {
            this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
              "1px solid red";
            this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
              "red 0px 0px 0px 0px";
            this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
              "red 0px 0px 0px 0px";
          }
        }
        if (!this.basicInfo.hasOwnProperty("secondary_mobile")) {
          this.hmobileValid = false;
          this.hmobileError = "Enter valid phone number";
          return;
        }
        var str = key;
        var res = str.substr(0, 9);
        this.basicInfo.secondary_mobile = res;
        key = res;
        if (key === "0") {
          this.basicInfo.secondary_mobile = "";
        }
        key = key.replace(/^0+/, "");
        key = key.replace(/ /g, "");
        this.basicInfo.secondary_mobile = key;

        if (key.length == 9) {
          if (key == this.basicInfo.mobile.substring(3)) {
            this.hmobileValid = false;
            this.hmobileError = "Existing Phone Number Detected";
            this.$refs.mobilecom.$children[1].$el.firstChild.style.borderColor =
              "red";
          } else {
            this.hmobileValid = true;
            this.$refs.mobilecom.$children[1].$el.firstChild.style.borderColor =
              "green";
          }
        } else if (key.length === 10 || key.length < 10) {
          this.hmobileValid = false;
          this.hmobileError = "Enter valid phone number";
        }
      } else {
        key = key.replace(/^0+/, "");
        if (key.length > 15 || key.length < 9) {
          this.hmobileValid = false;
        } else {
          this.hmobileValid = true;
        }
        this.basicInfo.secondary_mobile = key;
      }
    },
    fixedLineNumber(number) {
      if (number === "") {
        this.changeBorderFixedLine.border = "1px solid #ccc"
        this.fixedLineValid = true;
      } else {
        number = number.replace(/[^0-9]/g, "");
        this.basicInfo.secondary_mobile = number;
        if (/^[0-9]{10}$/.test(number)) {
          this.changeBorderFixedLine.border = "1px solid green";
          this.fixedLineValid = true;
        } else {
          this.changeBorderFixedLine.border = "1px solid red";
          this.fixedLineValid = false;
        }
      }
    },
    init() {
      this.basicInfo.dob = "";
      let status =
        localStorage.getItem("slt_customer_status") != undefined
          ? localStorage.getItem("slt_customer_status")
          : "";
      if (status == "enable") {
        this.isSltCustomer = true;
      } else if (status == "disable") {
        this.isSltCustomer = false;
      }
    },
    clickIsSltCustomer(payload) {
      if (payload) {
        this.$swal({
          title: "Please submit SLT customer details",
          text: "",
          type: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then((result) => {
          if (payload) {
            this.$parent.isSltCustomer = true;
            this.$parent.activeTab = "slt_cus";
            localStorage.setItem("slt_customer_status", "enable");
          } else {
            this.$parent.isSltCustomer = false;
            localStorage.setItem("slt_customer_status", "disable");
          }
        });
      } else {
        this.$parent.isSltCustomer = false;
        localStorage.setItem("slt_customer_status", "disable");
      }
    },
    close(val) {
      if (val === true) {
        this.getPersanalInfo();
      }
    },
    viewModalMobileVerify(val) {
      if (val) {
        $("mobileVerifyModalContentCom").modal("show");
        this.userType = "buyer";
        this.mobileForPropval = val;
      } else {
        this.$swal.fire({
          position: "center",
          type: "warning",
          title: "Enter the mobile number",
          showConfirmButton: false,
          timer: 150,
        });
      }
    },
    viewModalMobileVerify1(val) {
      if (val) {
        $("mobileVerifyModalContentCom").modal("show");
        this.userType = "buyer";
        this.mobileForPropval = val;
      }
    },
    getPersanalInfo: async function () {
      try {
        let response = await this.getProfileBacicInfo();
        if (response.gender != undefined) {
          this.selectedGender = response.gender;
        }

        if (response.hasOwnProperty("secondary_mobile")) {
          // let secMobile = JSON.parse(JSON.stringify(response.secondary_mobile));
          // secMobile = secMobile.substring(3);
          // response.secondary_mobile = secMobile;


          

          this.basicInfo = response;
          this.dialCode =
            response.fix_dial_code != undefined ? response.fix_dial_code : "LK";
          // this.validatePhone(response.secondary_mobile);
        } else {
          this.basicInfo = response;
        }
        // Check email verification
        if (response.email_verify == true) {
          this.emailVerified.display = "block";
          this.emailNotVerified.display = "none";
        } else {
          this.emailNotVerified.display = "block";
          this.emailVerified.display = "none";
        }

        if (this.basicInfo.image) {
          this.image = this.basicInfo.image;
        }

        this.mobile_verify = response.mobile_verify;
        // console.log("this.mobile_verify");
        // console.log(this.mobile_verify);
        

      } catch (error) {
        throw error;
      }
    },
    submitProfile: async function () {
      this.blockLoading = true;
      // this.basicInfo.dob = "";
      if (this.basicInfo.dob != null) {
        this.basicInfo.dob = moment(this.basicInfo.dob).format("YYYY/MM/DD");
      } else {
        delete this.basicInfo["dob"];
      }
      this.basicInfo.gender = this.selectedGender;

      let finalobj = JSON.parse(JSON.stringify(this.basicInfo));
      // if (this.basicInfo.secondary_mobile != undefined) {
      //   let countrcode =
      //     "+" +
      //     this.countryCode +
      //     this.basicInfo.secondary_mobile.replace(/^0+/, "");
      //   finalobj.fix_dial_code = this.dialCode;
      //   finalobj.fix_county_code = "+" + this.countryCode;
      //   finalobj.secondary_mobile = countrcode.replace(/ /g, "");
      // }

      finalobj.image = this.image;

      // console.log(finalobj);



      try {
        let response = await this.putProfileBacicInfo(finalobj);
        // this.$store.commit("setBuyerBasicInfo", {
        //   action: null,
        //   payload: this.basicInfo
        // });
        this.image = response.image;
        this.$store.commit("updateBuyerName", finalobj);
        this.fileUploadFormData = new FormData();
        this.getPersanalInfo();
        this.blockLoading = false;
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Submit success",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        this.blockLoading = false;
        throw error;
      }
    },
    handelBasicInfo() {
      this.$store.commit("setBuyerBasicInfo", {
        action: null,
        payload: this.basicInfo,
      });
    },
    pickProfPicture: function () {
      this.$refs.upprofpicture.click();
    },
    getFile: async function (eventf) {
      await this.fileUploadFormData.append("image", eventf.target.files[0]);

      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function () {
          self.image = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }

      try {
        let formData = await this.fileUploadFormData;
        let response = await self.putProfileImage(formData);
        if (response.hasOwnProperty("image")) {
          this.image = response.image;
          eventf.target.value = "";
        } else {
          this.image = "";
        }

        // this.$swal.fire({
        //   position: 'center',
        //   type: 'success',
        //   title: 'Submit Success',
        //   showConfirmButton: false,
        //   timer: 1500
        // })
        this.fileUploadFormData = new FormData();
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Submit Error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>
<style>
.buyerprofpersonal .buyerprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.buyerprofpersonal .buyerprof-sbtn:hover {
  color: #000000;
}
.buyerprofpersonal .profupload-btn {
  color: #ffffff;
}
.buyerprofpersonal .profupload-btn:hover {
  color: #000000;
}

/* .vdp-datepicker{
  height: 41px !important;
  border-radius: 4px !important;
} */
.datepnewclass {
  display: block;
  width: 100%;
  height: 41px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
@media screen and (max-width: 1200px) {
  #buyerImg #embedpollfileinput {
    margin-bottom: 15px;
  }
}
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #5779ae;
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

#preview-area {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  /* bottom: 60px; */
  right: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>