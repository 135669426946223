<template>
    <div>
        <p class="lead">SLT Customer</p>
        <div class="row">       
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">SLT ID (Omini Channel)</label>
                            <input type="text" class="form-control" placeholder="SLT ID">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Phone number (Can Store)</label>
                            <input type="number" class="form-control" placeholder="Phone number" >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Account Number</label>
                            <input type="number" class="form-control" placeholder="Account Number">
                        </div>
                    </div>
                </div>
                 <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">NIC (Can store)</label>
                            <input type="text" class="form-control" placeholder="NIC">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group text-right">
                    <button type="button" class="btn btn-primary" >Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {}
</script>
