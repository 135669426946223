import { render, staticRenderFns } from "./ProfileLeasing.vue?vue&type=template&id=60fa1580&scoped=true&"
import script from "./ProfileLeasing.vue?vue&type=script&lang=js&"
export * from "./ProfileLeasing.vue?vue&type=script&lang=js&"
import style0 from "./ProfileLeasing.vue?vue&type=style&index=0&id=60fa1580&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60fa1580",
  null
  
)

export default component.exports